import React from "react";
// import ProductPage from "../ProductsPage/ProductPage.jsx";
import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/stainless-steel/brands.png"

import p1 from "assets/img/products/stainless-steel/1.jpg";
import p2 from "assets/img/products/stainless-steel/2.jpg";
import p3 from "assets/img/products/stainless-steel/3.jpg";
import p4 from "assets/img/products/stainless-steel/4.jpg";
import p5 from "assets/img/products/stainless-steel/5.jpg";

import s1 from "assets/img/products/stainless-steel/slider/1.jpg";
import s2 from "assets/img/products/stainless-steel/slider/2.jpg";
import s3 from "assets/img/products/stainless-steel/slider/3.jpg";
import s4 from "assets/img/products/stainless-steel/slider/4.jpg";

export default () => (
  <ProductPage prod="Stainless Steel" url="stainless-steel" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2},
    { img: s3},
    { img: s4}
]

const products = [
    { name: 'Pipes & Fittings',      img: p1 },
    { name: 'Flanges',          img: p2 },
    { name: 'Stud Bolts',            img: p3 },
    { name: 'Gasket',           img: p4 },
    { name: 'Valves',           img: p5 }
]